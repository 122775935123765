import React, { useState, useEffect, useCallback } from 'react';
import {
    useRecordContext,
    ReferenceManyField,
    ReferenceField,
    TextField,
    BooleanField,
    Datagrid,
    FunctionField,
    useListContext,
    useDataProvider,
} from 'react-admin';
import { LinearProgress, Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AngenommenCheckbox, { CHECKBOX_CLICKED_EVENT } from './AngenommenCheckbox';
import moment from 'moment';

interface ConflictingTermin {
    id: number;
    vorschlagdatetime: string;
    bestellung: {
        id: number;
        produkt: {
            name: string;
        };
    };
}

interface MitarbeiterConflicts {
    [mitarbeiterId: number]: ConflictingTermin[];
}

const ZugewieseneMitarbeiter: React.FC = () => {
    const record = useRecordContext<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [mitarbeiterConflicts, setMitarbeiterConflicts] = useState<MitarbeiterConflicts>({});
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMitarbeiter, setSelectedMitarbeiter] = useState<{id: number, name: string} | null>(null);
    const dataProvider = useDataProvider();

    const fetchConflicts = useCallback(async () => {
        if (!record || !record.id) return;

        const { data: currentTerminvorschlag } = await dataProvider.getOne('terminvorschlag', { id: record.id });
        const currentDateTime = moment(currentTerminvorschlag.vorschlagdatetime);

        const { data: relMitarbeiterStatus } = await dataProvider.getList('relmitarbeiterstatus', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' },
            filter: { terminvorschlag_id: record.id },
        });

        const conflicts: MitarbeiterConflicts = {};

        for (const rel of relMitarbeiterStatus) {
            const { data: allTerminvorschlaege } = await dataProvider.getList('terminvorschlag', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'vorschlagdatetime', order: 'ASC' },
                filter: {},
            });

            const { data: mitarbeiterStatus } = await dataProvider.getList('relmitarbeiterstatus', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'ASC' },
                filter: { mitarbeiter_id: rel.mitarbeiter_id, bestaetigt: true },
            });

            const mitarbeiterConflicts = allTerminvorschlaege.filter(termin => {
                const terminDateTime = moment(termin.vorschlagdatetime);
                return mitarbeiterStatus.some(status => 
                    status.terminvorschlag_id === termin.id &&
                    termin.id !== record.id &&
                    Math.abs(currentDateTime.diff(terminDateTime, 'hours')) < 24
                );
            });

            if (mitarbeiterConflicts.length > 0) {
                const conflictDetails = await Promise.all(mitarbeiterConflicts.map(async conflict => {
                    const { data: bestellung } = await dataProvider.getOne('bestellung', { id: conflict.bestellung_id });
                    const { data: produkt } = await dataProvider.getOne('produkt', { id: bestellung.produkt_id });
                    return {
                        ...conflict,
                        bestellung: {
                            ...bestellung,
                            produkt: produkt,
                        },
                    };
                }));
                conflicts[rel.mitarbeiter_id] = conflictDetails;
            }
        }

        setMitarbeiterConflicts(conflicts);
        setIsLoading(false);
    }, [dataProvider, record]);

    useEffect(() => {
        fetchConflicts();
    }, [fetchConflicts]);

    useEffect(() => {
        const handleCheckboxClick = () => {
            setIsLoading(true);
            setTimeout(() => fetchConflicts(), 2500);
        };

        window.addEventListener(CHECKBOX_CLICKED_EVENT, handleCheckboxClick);

        return () => {
            window.removeEventListener(CHECKBOX_CLICKED_EVENT, handleCheckboxClick);
        };
    }, [fetchConflicts]);

    const handleOpenDialog = (event: React.MouseEvent, mitarbeiterId: number, mitarbeiterName: string) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedMitarbeiter({ id: mitarbeiterId, name: mitarbeiterName });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedMitarbeiter(null);
    };

    if (!record) return null;

    const ReferenceManyContent = () => {
        const { data, isLoading: listLoading } = useListContext();

        if (listLoading) {
            return (
                <Box sx={{ p: 2 }}>
                    <Typography>Loading...</Typography>
                </Box>
            );
        }

        return (
            <Datagrid data={data}>
                <ReferenceField source="mitarbeiter_id" reference="mitarbeiter" link={false}>
                    <FunctionField
                        render={(mitarbeiter: any) => (
                            <Box display="flex" alignItems="center">
                                <span>{`${mitarbeiter.nachname}, ${mitarbeiter.vorname}`}</span>
                                {mitarbeiterConflicts[mitarbeiter.id] && (
                                    <IconButton
                                        size="small"
                                        onClick={(event) => handleOpenDialog(event, mitarbeiter.id, `${mitarbeiter.nachname}, ${mitarbeiter.vorname}`)}
                                        sx={{ ml: 1, p: 0 }}
                                    >
                                        <NewReleasesIcon fontSize="small" color="warning" />
                                    </IconButton>
                                )}
                            </Box>
                        )}
                    />
                </ReferenceField>
                <BooleanField source="bestaetigt" />
                <FunctionField
                    label="Angenommen"
                    render={(relRecord: any) => (
                        <AngenommenCheckbox record={relRecord} />
                    )}
                />
            </Datagrid>
        );
    };

    return (
        <div>
            {isLoading && <LinearProgress />}
            <Box sx={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'auto' }}>
                <ReferenceManyField
                    label="Zugewiesene Mitarbeiter"
                    reference="relmitarbeiterstatus"
                    target="terminvorschlag_id"
                    filter={{ terminvorschlag_id: record.id }}
                >
                    <ReferenceManyContent />
                </ReferenceManyField>
            </Box>
            <Dialog 
                open={openDialog} 
                onClose={handleCloseDialog}
                onClick={(event) => event.stopPropagation()}
            >
                <DialogTitle>{`${selectedMitarbeiter?.name} hat für folgende Termine bereits zugesagt`}</DialogTitle>
                <DialogContent>
                    {selectedMitarbeiter && mitarbeiterConflicts[selectedMitarbeiter.id]?.map((termin) => (
                        <Typography key={termin.id}>
                            {`${termin.bestellung.produkt.name} - ${moment.utc(termin.vorschlagdatetime).local().format('DD.MM.YYYY HH:mm')}`}
                        </Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Schließen</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ZugewieseneMitarbeiter;