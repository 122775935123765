import React, { useState } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Card, CardContent, Button, Stepper, Step, StepLabel, Typography, LinearProgress} from '@mui/material';
import { createClient } from '@supabase/supabase-js';
import KundeAuswahl from './KundeAuswahl';
import ProduktAuswahl from './ProduktAuswahl';
import BestellungDetails from './BestellungDetails';
import MitarbeiterZuordnung from './MitarbeiterZuordnung';
import TerminvorschlagErstellung from './TerminvorschlagErstellung';
import Zusammenfassung from './Zusammenfassung';
import { sendEmailToMitarbeiter } from './sendEmail';
import { delay }from '../delay';



// Initialize Supabase client using environment variables
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);


export interface BestellungData {
    kunde_id: number | null;
    produkt_id: number | null;
    anzahltn: number;
    sonderwuensche: string;
    status: string;
    mitarbeiter: number[];
    vorschlagdatetime: string[] | null;
}

const steps = ['Kunde auswählen', 'Produkt auswählen', 'Bestellung Details', 'Terminvorschlag erstellen', 'Zusammenfassung'];

const NeueBestellungWizard: React.FC = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [bestellungData, setBestellungData] = useState<BestellungData>({
        kunde_id: null,
        produkt_id: null,
        anzahltn: 0,
        sonderwuensche: '',
        status: 'neu',
        mitarbeiter: [],
        vorschlagdatetime: []
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
  

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            handleSubmit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
/*
    const sendEmail = async (bestellungDetails: string) => {
        try {
            console.log('Attempting to send email...');
            const { data, error } = await supabase.functions.invoke('send-email', {
                body: {
                    to: 'kontakt@daniel-autenrieth.de',
                    subject: 'Anfrage Kulturelle Bildung',
                    html: `<h1>Neue Bestellung eingegangen</h1><p>Ein Auftrag ist eingegangen. Bitte entscheide in den nächsten 48h ob du an diesem annehmen möchtest oder nicht.</p><h2>Bestellungsdetails:</h2>${bestellungDetails}`,
                },
            });

            if (error) throw error;

            console.log('Email sent successfully:', data);
            notify('E-Mail erfolgreich gesendet', { type: 'success' });
        } catch (error) {
            console.error('Unexpected error sending email:', error);
            notify(`Unerwarteter Fehler beim Senden der E-Mail: ${error instanceof Error ? error.message : String(error)}`, { type: 'error' });
        }
    };*/

   /* const generateSimpleToken = async (terminId: number, mitarbeiterId: number): Promise<string> => {
        const message = `${terminId}:${mitarbeiterId}:${process.env.REACT_APP_TOKEN_SECRET}`;
        const encoder = new TextEncoder();
        const data = encoder.encode(message);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    };


    // good version
    const sendEmailToMitarbeiter = async (mitarbeiterId: number, bestellungDetails: string, terminvorschlaege: any[]) => {
        try {
            // Fetch mitarbeiter details
            const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', { id: mitarbeiterId });
            
            // Prepare email content with accept/decline buttons for each terminvorschlag
            const terminButtons = await Promise.all(terminvorschlaege.map(async (termin) => {
                const token = await generateSimpleToken(termin.id, mitarbeiterId);
                return `
                    <div>
                        <p>Vorschlag: ${new Date(termin.vorschlagdatetime).toLocaleString()}</p>
                        <a href="${process.env.REACT_APP_SUPABASE_URL}/functions/v1/respond-to-termin?action=accept&terminId=${termin.id}&mitarbeiterId=${mitarbeiterId}&token=${token}" style="padding: 10px; background-color: green; color: white; text-decoration: none; margin-right: 10px;">Akzeptieren</a>
                        <a href="${process.env.REACT_APP_SUPABASE_URL}/functions/v1/respond-to-termin?action=decline&terminId=${termin.id}&mitarbeiterId=${mitarbeiterId}&token=${token}" style="padding: 10px; background-color: red; color: white; text-decoration: none;">Ablehnen</a>
                    </div>
                `;
            }));

            const emailContent = `
                <h1>Neue Bestellung eingegangen</h1>
                <p>Hallo ${mitarbeiter.vorname},</p>
                <p>Ein neuer Auftrag ist eingegangen. Bitte entscheide, ob du an folgenden Terminen Zeit hast:</p>
                <h2>Bestellungsdetails:</h2>
                ${bestellungDetails}
                <h2>Terminvorschläge:</h2>
                ${terminButtons.join('')}
            `;

            const { data, error } = await supabase.functions.invoke('send-email', {
                body: {
                    to: "kontakt@daniel-autenrieth.de", //mitarbeiter.email,
                    subject: 'Neue Bestellung für kulturelle Bildung',
                    html: emailContent,
                },
            });

            if (error) throw error;

            console.log('Email sent successfully to:', mitarbeiter.email);
        } catch (error) {
            console.error('Error sending email to mitarbeiter:', error);
            notify(`Fehler beim Senden der E-Mail an Mitarbeiter: ${error instanceof Error ? error.message : String(error)}`, { type: 'error' });
        }
    };*/

    const handleSubmit = async () => {
    try {
        // Create Bestellung
        const { data: bestellung } = await dataProvider.create('bestellung', { data: {
            kunde_id: bestellungData.kunde_id,
            produkt_id: bestellungData.produkt_id,
            anzahltn: bestellungData.anzahltn,
            sonderwuensche: bestellungData.sonderwuensche,
            status: bestellungData.status
        }});

        // Create Terminvorschläge
        const terminvorschlaege = [];
        if (Array.isArray(bestellungData.vorschlagdatetime)) {
            for (const vorschlag of bestellungData.vorschlagdatetime) {
                const { data: terminvorschlag } = await dataProvider.create('terminvorschlag', { data: {
                    bestellung_id: bestellung.id,
                    vorschlagdatetime: vorschlag,
                    status: 'vorgeschlagen'
                }});
                terminvorschlaege.push(terminvorschlag);
            }
        }

        // Fetch associated mitarbeiter for the product
        const { data: associatedMitarbeiter } = await dataProvider.getList('relproduktmitarbeiter', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' },
            filter: { produkt_id: bestellungData.produkt_id }
        });

        // Prepare bestellung details for email
        const bestellungDetails = `
            <ul>
                <li>Kunde ID: ${bestellungData.kunde_id}</li>
                <li>Produkt ID: ${bestellungData.produkt_id}</li>
                <li>Anzahl Teilnehmer: ${bestellungData.anzahltn}</li>
                <li>Sonderwünsche: ${bestellungData.sonderwuensche}</li>
                <li>Status: ${bestellungData.status}</li>
            </ul>
        `;

          // Show a progress indicator
    setIsLoading(true);
    setProgress(0);
    const totalMitarbeiter = associatedMitarbeiter.length;

    // Send personalized emails to each associated mitarbeiter with delay
    for (let i = 0; i < associatedMitarbeiter.length; i++) {
      const relation = associatedMitarbeiter[i];

      await sendEmailToMitarbeiter(
        dataProvider,
        notify,
        relation.mitarbeiter_id,
        bestellungDetails,
        terminvorschlaege
      );

      // Update progress
      setProgress(((i + 1) / totalMitarbeiter) * 100);

      // Introduce a delay of 500ms between each email to stay within rate limits
      await delay(500);
    }

        // Hide the progress indicator
        setIsLoading(false);

        notify('Bestellung erfolgreich erstellt und E-Mails versendet', { type: 'success' });
        redirect('/bestellung');
    } catch (error) {
        console.error('Error creating Bestellung:', error);
        notify('Fehler beim Erstellen der Bestellung', { type: 'error' });
        setIsLoading(false);
    }
};

const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <KundeAuswahl
            bestellungData={bestellungData}
            setBestellungData={setBestellungData}
          />
        );
      case 1:
        return (
          <ProduktAuswahl
            bestellungData={bestellungData}
            setBestellungData={setBestellungData}
          />
        );
      case 2:
        return (
          <BestellungDetails
            bestellungData={bestellungData}
            setBestellungData={setBestellungData}
          />
        );
      case 3:
        return (
          <TerminvorschlagErstellung
            bestellungData={bestellungData}
            setBestellungData={setBestellungData}
          />
        );
      case 4:
        return <Zusammenfassung bestellungData={bestellungData} />;
      default:
        return 'Unknown step';
    }
  };
  

  return (
    <Card>
        <CardContent>
            <Typography variant="h5" gutterBottom>
                Neue Bestellung erstellen
            </Typography>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <React.Fragment>
                {getStepContent(activeStep)}
                <div>
                    <Button disabled={activeStep === 0 || isLoading} onClick={handleBack}>
                        Zurück
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={isLoading}
                    >
                        {activeStep === steps.length - 1 ? 'Matching Prozess starten' : 'Weiter'}
                    </Button>
                </div>
            </React.Fragment>
            {isLoading && (
                <div>
                    <Typography variant="body1">E-Mails werden gesendet...</Typography>
                    <LinearProgress variant="determinate" value={progress} />
                </div>
            )}
        </CardContent>
    </Card>
);
      
};

export default NeueBestellungWizard;