import React from 'react';
import { Create, SimpleForm, DateTimeInput, ReferenceInput, SelectInput } from 'react-admin';

export const TerminvorschlagCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="bestellung_id" reference="bestellung">
                <SelectInput optionText="id" />
            </ReferenceInput>
            <DateTimeInput source="vorschlagdatum" />
            <SelectInput source="status" choices={[
                { id: 'vorgeschlagen', name: 'Vorgeschlagen' },
                { id: 'akzeptiert', name: 'Akzeptiert' },
                { id: 'abgelehnt', name: 'Abgelehnt' },
            ]} />
        </SimpleForm>
    </Create>
);