import React from 'react';
import { List, Datagrid, TextField, EmailField, BooleanField, NumberField } from 'react-admin';

export const MitarbeiterList = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="vorname" />
            <TextField source="nachname" />
            <TextField source="adresse" />
            <TextField source="plz" />
            <TextField source="ort" />
            <EmailField source="email" />
            <TextField source="festnetz" />
            <TextField source="mobil" />
            <NumberField source="fahrtkosten" options={{ style: 'currency', currency: 'EUR' }} />
            <BooleanField source="umsatzsteuer" />
        </Datagrid>
    </List>
);