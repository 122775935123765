import React, { useState, useEffect } from 'react';
import { TextField, Button, Alert } from '@mui/material';
import { useDataProvider } from 'react-admin';
import { BestellungData } from './NeueBestellungWizard';
import moment from 'moment';

interface TerminvorschlagErstellungProps {
    bestellungData: BestellungData;
    setBestellungData: React.Dispatch<React.SetStateAction<BestellungData>>;
}

interface Blockierung {
    id: number;
    museum_id: number;
    start: string;
    ende: string;
}

interface Terminvorschlag {
    id: number;
    bestellung_id: number;
    vorschlagdatetime: string;
    status: string;
}

interface Produkt {
    id: number;
    name: string;
    museum_id: number;
    dauer: number;
}

const TerminvorschlagErstellung: React.FC<TerminvorschlagErstellungProps> = ({ bestellungData, setBestellungData }) => {
    const [blockierungen, setBlockierungen] = useState<Blockierung[]>([]);
    const [acceptedTermine, setAcceptedTermine] = useState<Terminvorschlag[]>([]);
    const [conflicts, setConflicts] = useState<string[]>([]);
    const [produkt, setProdukt] = useState<Produkt | null>(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchData = async () => {
            if (!bestellungData.produkt_id) return;

            const { data: produktData } = await dataProvider.getOne('produkt', { id: bestellungData.produkt_id });
            setProdukt(produktData);

            const { data: blockierungenData } = await dataProvider.getList('blockierung', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'start', order: 'ASC' },
                filter: { museum_id: produktData.museum_id }
            });
            setBlockierungen(blockierungenData);

            const { data: termineData } = await dataProvider.getList('terminvorschlag', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'vorschlagdatetime', order: 'ASC' },
                filter: { status: 'akzeptiert' }
            });

            const filteredTermine = await Promise.all(termineData.map(async (termin) => {
                const { data: bestellung } = await dataProvider.getOne('bestellung', { id: termin.bestellung_id });
                const { data: produktForTermin } = await dataProvider.getOne('produkt', { id: bestellung.produkt_id });
                return produktForTermin.museum_id === produktData.museum_id ? termin : null;
            }));

            setAcceptedTermine(filteredTermine.filter((termin): termin is Terminvorschlag => termin !== null));
        };

        fetchData();
    }, [dataProvider, bestellungData.produkt_id]);

    const checkConflicts = (newDateTime: string): string[] => {
        if (!produkt) return [];

        const newDateMoment = moment.utc(newDateTime);
        const endDateMoment = moment.utc(newDateTime).add(produkt.dauer, 'hours');
        const conflicts: string[] = [];

        const hasOverlap = (start1: moment.Moment, end1: moment.Moment, start2: moment.Moment, end2: moment.Moment) => {
            return (start1.isBefore(end2) && end1.isAfter(start2));
        };

        blockierungen.forEach(blockierung => {
            const startMoment = moment.utc(blockierung.start);
            const endMoment = moment.utc(blockierung.ende);
            if (hasOverlap(newDateMoment, endDateMoment, startMoment, endMoment)) {
                conflicts.push(`Konflikt mit Blockierung: ${startMoment.local().format('DD.MM.YYYY HH:mm')} - ${endMoment.local().format('DD.MM.YYYY HH:mm')}`);
            }
        });

        acceptedTermine.forEach(termin => {
            const terminMoment = moment.utc(termin.vorschlagdatetime);
            const terminEndMoment = moment.utc(termin.vorschlagdatetime).add(produkt.dauer, 'hours');
            if (hasOverlap(newDateMoment, endDateMoment, terminMoment, terminEndMoment)) {
                conflicts.push(`Konflikt mit Veranstaltung an gleichem Ort: ${terminMoment.local().format('DD.MM.YYYY HH:mm')} - ${terminEndMoment.local().format('DD.MM.YYYY HH:mm')}`);
            }
        });

        return conflicts;
    };

    const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newDateTime = moment(event.target.value).utc().format();
        const newConflicts = checkConflicts(newDateTime);
        setConflicts(newConflicts);

        const newTerminvorschlaege = [...(bestellungData.vorschlagdatetime || [])];
        newTerminvorschlaege[index] = newDateTime;
        setBestellungData(prev => ({ ...prev, vorschlagdatetime: newTerminvorschlaege }));
    };

    const addTerminvorschlag = () => {
        setBestellungData(prev => ({ 
            ...prev, 
            vorschlagdatetime: [...(prev.vorschlagdatetime || []), ''] 
        }));
    };

    return (
        <>
            {conflicts.length > 0 && (
                <Alert severity="warning">
                    {conflicts.map((conflict, index) => (
                        <div key={index}>{conflict}</div>
                    ))}
                </Alert>
            )}
            {(bestellungData.vorschlagdatetime || []).map((vorschlag, index) => (
                <TextField
                    key={index}
                    fullWidth
                    label={`Vorgeschlagenes Datum und Zeit ${index + 1}`}
                    type="datetime-local"
                    value={vorschlag ? moment.utc(vorschlag).local().format('YYYY-MM-DDTHH:mm') : ''}
                    onChange={(e) => handleDateTimeChange(e as React.ChangeEvent<HTMLInputElement>, index)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                />
            ))}
            <Button onClick={addTerminvorschlag}>Weiteren Terminvorschlag hinzufügen</Button>
        </>
    );
};

export default TerminvorschlagErstellung;