import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const MuseumList = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="adresse" />
            <TextField source="plz" />
            <TextField source="ort" />
            <TextField source="color" />
        </Datagrid>
    </List>
);