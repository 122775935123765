// CustomLayout.tsx
import React from 'react';
import {
  AppBar,
  useTranslate,
  AppBarProps,
  Layout,
  LayoutProps,
  usePermissions,
} from 'react-admin';
import { Toolbar, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from './KulturamtLogoWeiss.png';
import CustomMenu from './CustomMenu';
import AddIcon from '@mui/icons-material/Add';

const CustomAppBar: React.FC<AppBarProps> = (props) => {
  const translate = useTranslate();
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <AppBar {...props} sx={{ backgroundColor: '#56555d' }}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ flex: 1, minWidth: 0, display: 'flex' }} />

        <Box sx={{ flex: 0, display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '40px' }} />
        </Box>

        <Box
          sx={{
            flex: 1,
            minWidth: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {permissions === 'admin' && (
            <Button
              color="inherit"
              component={Link}
              to="/neue-bestellung"
              sx={{ marginRight: 2 }}
              startIcon={<AddIcon />}
            >
              {translate('Neue Bestellung')}
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export { CustomAppBar };

export const CustomLayout: React.FC<LayoutProps> = (props) => (
  <Layout
    {...props}
    appBar={CustomAppBar}
    menu={CustomMenu}
    sx={{
      '& .RaLayout-contentWithSidebar': {
        marginTop: '1em',
      },
      '& .RaLayout-content': {
        marginTop: '1em',
      },
    }}
  />
);

export default CustomLayout;