import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin';

export const ProduktCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="museum_id" reference="museum">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" />
            <NumberInput source="dauer" />
            <NumberInput source="kosten" />
            <SelectInput source="fuereinrichtung" choices={[
                { id: 'kita', name: 'Kita' },
                { id: 'grundschule', name: 'Grundschule' },
                { id: 'sek 1', name: 'Sek 1' },
                { id: 'sek 2', name: 'Sek 2' },
                { id: 'vabo', name: 'VABO' },
                { id: 'vklgs', name: 'VKL Grundschule' },
                { id: 'vksek1', name: 'VKL Sek 1' },
                { id: 'andere', name: 'Andere' },
            ]} />
            <NumberInput source="maxtn" />
            <NumberInput source="anzahlmitarbeiter" />
        </SimpleForm>
    </Create>
);

export default ProduktCreate;