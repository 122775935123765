import React, { useEffect, useState, useCallback } from 'react';
import {
    useDataProvider,
    useNotify,
    useRecordContext,
    Button,
    SelectInput,
    Form
} from 'react-admin';
import { Box, Chip, Typography } from '@mui/material';

interface Mitarbeiter {
    id: number;
    vorname: string;
    nachname: string;
}

export const ProduktMitarbeiterAssociation: React.FC = () => {
    const record = useRecordContext();
    const [allMitarbeiter, setAllMitarbeiter] = useState<Mitarbeiter[]>([]);
    const [associatedMitarbeiter, setAssociatedMitarbeiter] = useState<Mitarbeiter[]>([]);
    const [selectedMitarbeiter, setSelectedMitarbeiter] = useState<number | null>(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchData = useCallback(async () => {
        if (!record || !record.id) return;
    
        try {
            console.log('Fetching data for product ID:', record.id);
    
            // Fetch all Mitarbeiter
            const { data: allMitarbeiterData } = await dataProvider.getList('mitarbeiter', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'nachname', order: 'ASC' },
                filter: {}
            });
            setAllMitarbeiter(allMitarbeiterData);
    
            // Fetch associated Mitarbeiter for this specific product
            const { data: productMitarbeiter } = await dataProvider.getList('relproduktmitarbeiter', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'ASC' },
                filter: { produkt_id: record.id }
            });
            console.log('Product-Mitarbeiter associations:', productMitarbeiter);
    
            // Get the associated Mitarbeiter for this specific product
            const associatedIds = productMitarbeiter.map(pm => pm.mitarbeiter_id);
            const associated = allMitarbeiterData.filter(m => associatedIds.includes(m.id));
            setAssociatedMitarbeiter(associated);
    
        } catch (error) {
            console.error('Error fetching data:', error);
            notify('Error fetching data', { type: 'error' });
        }
    }, [dataProvider, record, notify]);


    useEffect(() => {
        fetchData();
    }, [fetchData, record]);

    const handleAssociate = async () => {
        if (!record || !record.id || !selectedMitarbeiter) return;

        try {
            await dataProvider.create('relproduktmitarbeiter', {
                data: { produkt_id: record.id, mitarbeiter_id: selectedMitarbeiter }
            });
            notify('Mitarbeiter:in hinzugefügt', { type: 'success' });
            await fetchData();
            setSelectedMitarbeiter(null);
        } catch (error) {
            console.error('Error associating Mitarbeiter:', error);
            notify('Error associating Mitarbeiter', { type: 'error' });
        }
    };

    const handleDissociate = async (mitarbeiterId: number) => {
        if (!record || !record.id) return;

        try {
            const { data: associationToDelete } = await dataProvider.getList('relproduktmitarbeiter', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { produkt_id: record.id, mitarbeiter_id: mitarbeiterId }
            });

            if (associationToDelete.length > 0) {
                await dataProvider.delete('relproduktmitarbeiter', {
                    id: associationToDelete[0].id
                });
                notify('Mitarbeiter:in entfernt', { type: 'success' });
                await fetchData();
            }
        } catch (error) {
            console.error('Error dissociating Mitarbeiter:', error);
            notify('Error dissociating Mitarbeiter', { type: 'error' });
        }
    };

    if (!record) return null;

    const availableMitarbeiter = allMitarbeiter.filter(m => !associatedMitarbeiter.some(am => am.id === m.id));

    return (
        <Box>
            <Typography variant="h6">Zugeordnete Mitarbeiter:innen</Typography>
            <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                {associatedMitarbeiter.map((mitarbeiter) => (
                    <Chip
                        key={mitarbeiter.id}
                        label={`${mitarbeiter.vorname} ${mitarbeiter.nachname}`}
                        onDelete={() => handleDissociate(mitarbeiter.id)}
                    />
                ))}
            </Box>

            <Form>
                <Box display="flex" alignItems="flex-end" gap={2}>
                    <SelectInput
                        source="mitarbeiter_id"
                        choices={availableMitarbeiter.map(m => ({ id: m.id, name: `${m.vorname} ${m.nachname}` }))}
                        onChange={(e) => setSelectedMitarbeiter(e.target.value as number)}
                        value={selectedMitarbeiter}
                    />
                    <Button label="Hinzufügen" onClick={handleAssociate} disabled={!selectedMitarbeiter} />
                </Box>
            </Form>
        </Box>
    );
};

export default ProduktMitarbeiterAssociation;