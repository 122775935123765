import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    NumberField, 
    EditButton,
    SearchInput,
    FunctionField,
    useRecordContext
} from 'react-admin';
import CustomDeleteButton from './CustomDeleteButton';
import moment from 'moment-timezone';
import ArchiveButton from './ArchiveButton';

const bestellungFilters = [
    <SearchInput source="q" alwaysOn placeholder="Suche nach Id" />,
];

interface Terminvorschlag {
    id: number;
    bestellung_id: number;
    vorschlagdatetime: string;
    status: string;
}

interface Bestellung {
    id: number;
    kunde_id: number;
    produkt_id: number;
    anzahltn: number;
    sonderwuensche: string;
    status: string;
    created_at: string;
    terminvorschlaege?: Terminvorschlag[];
}

const LocalDateField: React.FC<{ date: string }> = ({ date }) => {
    if (!date) return null;
    const localTime = moment.utc(date).local().format('DD.MM.YYYY HH:mm:ss');
    return <span>{localTime}</span>;
};

const ColorLegend = () => (
    <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: 'lightgreen', marginRight: '5px' }}></div>
            <span>Mindestens 1 Match wurde gefunden</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: 'lightblue', marginRight: '5px' }}></div>
            <span>Ein Veranstaltungstermin wurde festgelegt</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: 'pink', marginRight: '5px' }}></div>
            <span>Älter als 48 Stunden ohne akzeptierten Termin</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: 'lightgray', marginRight: '5px' }}></div>
            <span>Stornierte Bestellung</span>
        </div>
    </div>
);

const TerminvorschlagField: React.FC = () => {
    const record = useRecordContext<Bestellung>();
    if (!record || !record.terminvorschlaege) {
        return <>-</>;
    }
    
    const matchingTerminvorschlag = record.terminvorschlaege.find(tv => tv.status === "akzeptiert");
    
    if (matchingTerminvorschlag) {
        return <LocalDateField date={matchingTerminvorschlag.vorschlagdatetime} />;
    } else {
        return <>-</>;
    }
};

const rowStyle = (record: Bestellung): React.CSSProperties => {
    if (!record) return {};
    
    if (record.status === 'storniert') {
        return { backgroundColor: 'lightgray' };
    }
    
    if (!record.terminvorschlaege) return {};
    
    const now = moment();
    const createdAt = moment(record.created_at);
    const isOlderThan48Hours = now.diff(createdAt, 'hours') > 48;
    
    const acceptedTerminvorschlag = record.terminvorschlaege.find(tv => tv.status === "akzeptiert");
    const matchingTerminvorschlag = record.terminvorschlaege.find(tv => tv.status === "match");
    
    if (isOlderThan48Hours && !acceptedTerminvorschlag) {
        return { backgroundColor: 'pink' };
    } else if (acceptedTerminvorschlag) {
        return { backgroundColor: 'lightblue' };
    } else if (matchingTerminvorschlag) {
        return { backgroundColor: 'lightgreen' };
    } else {
        return {};
    }
};

export const BestellungList: React.FC = (props) => (
    <>
        <ColorLegend />
        <List {...props} filters={bestellungFilters} filter={{ archived: false }}>
            <Datagrid rowClick="edit" rowStyle={rowStyle}>
                <TextField source="id" />
                <ReferenceField source="kunde_id" reference="kunde">
                    <TextField source="einrichtung" />
                </ReferenceField>
                <ReferenceField source="kunde_id" reference="kunde" label="Ansprechpartner">
                    <TextField source="ansprechpartner" />
                </ReferenceField>
                <ReferenceField source="produkt_id" reference="produkt">
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="anzahltn" />
                <TextField source="sonderwuensche" />
                <TextField source="status" />
                <FunctionField label="Terminvorschlag" render={TerminvorschlagField} />
                <EditButton />
                <ArchiveButton />
            </Datagrid>
        </List>
    </>
);

export default BestellungList;