import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { BestellungData } from './NeueBestellungWizard';

interface ZusammenfassungProps {
    bestellungData: BestellungData;
}

const Zusammenfassung: React.FC<ZusammenfassungProps> = ({ bestellungData }) => {
    return (
        <List>
            <ListItem>
                <ListItemText primary="Kunde ID" secondary={bestellungData.kunde_id} />
            </ListItem>
            <ListItem>
                <ListItemText primary="Produkt ID" secondary={bestellungData.produkt_id} />
            </ListItem>
            <ListItem>
                <ListItemText primary="Anzahl Teilnehmer" secondary={bestellungData.anzahltn} />
            </ListItem>
            <ListItem>
                <ListItemText primary="Sonderwünsche" secondary={bestellungData.sonderwuensche} />
            </ListItem>

            <ListItem>
            <ListItemText 
    primary="Terminvorschläge" 
    secondary={
        bestellungData.vorschlagdatetime 
            ? bestellungData.vorschlagdatetime.map(datetime => new Date(datetime).toLocaleString()).join(', ')
            : 'Keine Terminvorschläge'
    } 
/>
            </ListItem>
        </List>
    );
};

export default Zusammenfassung;