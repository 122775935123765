// sendEinzelauftrag.ts
import axios from 'axios';
import { DataProvider } from 'react-admin';
import moment from 'moment-timezone';

moment.tz.setDefault("Europe/Berlin");

interface EinzelauftragData {
  mitarbeiterId: number;
  terminvorschlagId: number;
  produktName: string;
  termin: string;
  museum: string;
  mitarbeiterEmail: string;
  mitarbeiterVorname: string;
  mitarbeiterNachname: string;
}

export const sendEinzelauftrag = async (
  dataProvider: DataProvider,
  data: EinzelauftragData
) => {
  try {
    // Fetch necessary data efficiently
    const [{ data: mitarbeiter }, { data: terminvorschlag }] = await Promise.all([
      dataProvider.getOne('mitarbeiter', { id: data.mitarbeiterId }),
      dataProvider.getOne('terminvorschlag', { id: data.terminvorschlagId }),
    ]);

    if (!mitarbeiter || !terminvorschlag) {
      throw new Error('Required data not found');
    }

    // Fetch additional data
    const [{ data: bestellung }, { data: produkt }, { data: museum }] = await Promise.all([
      dataProvider.getOne('bestellung', { id: terminvorschlag.bestellung_id }),
      dataProvider.getOne('bestellung', { id: terminvorschlag.bestellung_id }).then(res => 
        dataProvider.getOne('produkt', { id: res.data.produkt_id })
      ),
      dataProvider.getOne('bestellung', { id: terminvorschlag.bestellung_id }).then(res => 
        dataProvider.getOne('produkt', { id: res.data.produkt_id }).then(prodRes => 
          dataProvider.getOne('museum', { id: prodRes.data.museum_id })
        )
      ),
    ]);

    if (!bestellung || !produkt || !museum) {
      throw new Error('Required data not found');
    }

    // Convert UTC to local time and calculate end time
    const startTime = moment.utc(terminvorschlag.vorschlagdatetime).local();
    const endTime = startTime.clone().add(produkt.dauer, 'hours');
    const formattedTimeRange = `${startTime.format('DD.MM.YYYY HH:mm')} - ${endTime.format('HH:mm')}`;

    // Prepare data to send to the Edge Function
    const payload = {
      mitarbeiterEmail: mitarbeiter.email,
      produktName: produkt.name,
      termin: formattedTimeRange,
      museum: museum.name,
      mitarbeiterVorname: mitarbeiter.vorname,
      mitarbeiterNachname: mitarbeiter.nachname,
    };

    // Call the Edge Function
    const EDGE_FUNCTION_URL = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/sendEinzelauftrag`;

    const response = await axios.post(EDGE_FUNCTION_URL, payload);

    console.log('Einzelauftrag sent successfully:', response.data[0]?.slug);
    return response.data[0]?.slug;
  } catch (error) {
    console.error('Error sending Einzelauftrag:', error);
    throw error;
  }
};