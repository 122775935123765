import React from 'react';
import { Create, SimpleForm, TextInput, email, SelectInput } from 'react-admin';

export const KundeCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="einrichtung" />
            <TextInput source="adresse" multiline />
            <TextInput source="plz" />
            <TextInput source="ort" />
            <SelectInput 
                source="anrede" 
                choices={[
                    { id: 'Herr', name: 'Herr' },
                    { id: 'Frau', name: 'Frau' },
                    { id: 'Divers', name: 'Divers' },
                ]}
            />
            <TextInput source="ansprechpartner" />
            <TextInput source="telefon" />
            <TextInput source="email" validate={[email()]} />
        </SimpleForm>
    </Create>
);

export default KundeCreate;