import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    BooleanInput,
    useRecordContext
} from 'react-admin';
import ProduktMitarbeiterAssociation from './ProduktMitarbeiterAssociation';

const ProduktTitle = () => {
    const record = useRecordContext();
    return <span>Produkt {record ? `"${record.name}"` : ''}</span>;
};

export const ProduktEdit = (props: any) => (
    <Edit title={<ProduktTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="museum_id" reference="museum">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" />
            <NumberInput source="dauer" />
            <NumberInput source="kosten" />
            <SelectInput source="fuereinrichtung" choices={[
                { id: 'kita', name: 'Kita' },
                { id: 'grundschule', name: 'Grundschule' },
                { id: 'sek 1', name: 'Sek 1' },
                { id: 'sek 2', name: 'Sek 2' },
                { id: 'vabo', name: 'VABO' },
                { id: 'vklgs', name: 'VKL Grundschule' },
                { id: 'vksek1', name: 'VKL Sek 1' },
                { id: 'andere', name: 'Andere' },
            ]} />
            <NumberInput source="maxtn" />
            <NumberInput source="anzahlmitarbeiter" />
            <ProduktMitarbeiterAssociation />
        </SimpleForm>
    </Edit>
);

export default ProduktEdit;