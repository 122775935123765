import React, { useState, useEffect } from 'react';
import { useDataProvider, useGetIdentity } from 'react-admin';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, CircularProgress, Typography, Paper } from '@mui/material';

moment.locale('de', {
  week: {
    dow: 1,
    doy: 4
  }
});

const localizer = momentLocalizer(moment);

interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  status: string;
  color: string;
  terminvorschlagId?: number;
  bestellungId?: number;
  isBlockierung?: boolean;
  grund?: string;
}

interface TerminDetails {
  kunde: {
    einrichtung: string;
    ansprechpartner: string;
    email: string;
    telefon: string;
  };
  anzahltn: number;
  sonderwuensche: string;
}

const EmployeeTerminKalender = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [terminDetails, setTerminDetails] = useState<TerminDetails | null>(null);
  const [coWorkers, setCoWorkers] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeName, setEmployeeName] = useState('');
  const dataProvider = useDataProvider();
  const { identity, isLoading: isIdentityLoading } = useGetIdentity();

  useEffect(() => {
    const fetchEvents = async () => {
      if (isIdentityLoading || !identity?.id) return;

      try {
        setIsLoading(true);
        
        // Fetch employee details using auth_user_id
        const { data: mitarbeiterList } = await dataProvider.getList('mitarbeiter', {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: { auth_user_id: identity.id },
        });

        if (mitarbeiterList.length === 0) {
          console.error('No mitarbeiter found for this user');
          setIsLoading(false);
          return;
        }

        const mitarbeiter = mitarbeiterList[0];
        setEmployeeName(`${mitarbeiter.vorname} ${mitarbeiter.nachname}`);

        // Fetch all terminvorschlaege
        const { data: termine } = await dataProvider.getList('terminvorschlag', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'vorschlagdatetime', order: 'ASC' },
          filter: { status: 'akzeptiert' },
        });

        // Fetch relmitarbeiterstatus for this mitarbeiter
        const { data: relMitarbeiterStatus } = await dataProvider.getList('relmitarbeiterstatus', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'ASC' },
          filter: { mitarbeiter_id: mitarbeiter.id, angenommen: true },
        });

        const mitarbeiterTerminIds = new Set(relMitarbeiterStatus.map(rel => rel.terminvorschlag_id));

        const terminePromises = termine
          .filter(termin => mitarbeiterTerminIds.has(termin.id))
          .map(async (termin: any) => {
            const { data: bestellung } = await dataProvider.getOne('bestellung', { id: termin.bestellung_id });
            
            // Skip if bestellung is canceled
            if (bestellung.status === 'storniert') {
              return null;
            }
            
            const { data: produkt } = await dataProvider.getOne('produkt', { id: bestellung.produkt_id });
            const { data: museum } = await dataProvider.getOne('museum', { id: produkt.museum_id });

            const startTime = moment.utc(termin.vorschlagdatetime).local().toDate();
            const endTime = moment.utc(termin.vorschlagdatetime).local().add(produkt.dauer, 'hours').toDate();
           
            return {
              title: `${produkt.name} - ${museum.name}`,
              start: startTime,
              end: endTime,
              status: termin.status,
              color: museum.color || '#3174ad',
              terminvorschlagId: termin.id,
              bestellungId: bestellung.id,
              isBlockierung: false,
            };
          });

        // Fetch all blockierungen
        const { data: blockierungen } = await dataProvider.getList('blockierung', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'start', order: 'ASC' },
        });

        const blockierungenEvents = blockierungen.map((blockierung: any) => ({
          title: `Blockierung: ${blockierung.name}`,
          start: moment.utc(blockierung.start).local().toDate(),
          end: moment.utc(blockierung.ende).local().toDate(),
          status: 'blockierung',
          color: 'red',
          isBlockierung: true,
          grund: blockierung.grund,
        }));

        const termineEvents = (await Promise.all(terminePromises))
          .filter(event => event !== null) as CalendarEvent[];
        setEvents([...termineEvents, ...blockierungenEvents]);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, [dataProvider, identity, isIdentityLoading]);

  const eventStyleGetter = (event: CalendarEvent) => {
    if (event.isBlockierung) {
      return {
        style: {
          backgroundColor: 'red',
          color: 'white',
          borderRadius: '0px',
          opacity: 0.8,
          border: 'none',
          display: 'block'
        }
      };
    }
    return {
      style: {
        backgroundColor: event.color,
      },
    };
  };

  const handleEventClick = async (event: CalendarEvent) => {
    setSelectedEvent(event);

    if (event.isBlockierung) {
      setIsDialogOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      // Fetch co-workers
      const { data: relMitarbeiterStatus } = await dataProvider.getList('relmitarbeiterstatus', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
        filter: { terminvorschlag_id: event.terminvorschlagId, angenommen: true },
      });

      const coWorkerPromises = relMitarbeiterStatus.map(async (rel) => {
        const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', { id: rel.mitarbeiter_id });
        return `${mitarbeiter.vorname} ${mitarbeiter.nachname}`;
      });

      const coWorkerNames = await Promise.all(coWorkerPromises);
      setCoWorkers(coWorkerNames);

      // Fetch bestellung details
      const { data: bestellung } = await dataProvider.getOne('bestellung', { id: event.bestellungId });
      
      // Fetch kunde details
      const { data: kunde } = await dataProvider.getOne('kunde', { id: bestellung.kunde_id });

      setTerminDetails({
        kunde: {
          einrichtung: kunde.einrichtung,
          ansprechpartner: kunde.ansprechpartner,
          email: kunde.email,
          telefon: kunde.telefon,
        },
        anzahltn: bestellung.anzahltn,
        sonderwuensche: bestellung.sonderwuensche,
      });

      setIsDialogOpen(true);
    } catch (error) {
      console.error('Error loading event details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedEvent(null);
    setCoWorkers([]);
    setTerminDetails(null);
  };

  if (isLoading || isIdentityLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const upcomingAppointments = events
    .filter(event => !event.isBlockierung && event.start > new Date())
    .sort((a, b) => a.start.getTime() - b.start.getTime())
    .slice(0, 5);

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Hallo {employeeName}!
      </Typography>
      <div style={{ height: '500px', marginBottom: '20px' }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleEventClick}
          views={['month', 'week', 'day']}
          formats={{
            timeGutterFormat: 'HH:mm',
            eventTimeRangeFormat: ({ start, end }: { start: Date; end: Date }) => (
              `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
            ),
          }}
        />
      </div>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Deine nächsten Termine:
        </Typography>
        {upcomingAppointments.length > 0 ? (
          <List>
            {upcomingAppointments.map((event, index) => (
              <ListItem 
                key={index} 
                button 
                onClick={() => handleEventClick(event)}
                style={{ cursor: 'pointer' }}
              >
                <ListItemText
                  primary={event.title}
                  secondary={`${moment(event.start).format('DD.MM.YYYY HH:mm')} - ${moment(event.end).format('HH:mm')}`}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>Keine anstehenden Termine.</Typography>
        )}
      </Paper>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{selectedEvent?.title}</DialogTitle>
        <DialogContent>
          <Typography>Beginn: {moment(selectedEvent?.start).format('MMMM Do YYYY, HH:mm')}</Typography>
          <Typography>Ende: {moment(selectedEvent?.end).format('MMMM Do YYYY, HH:mm')}</Typography>
          
          {selectedEvent?.isBlockierung ? (
            <Typography style={{ marginTop: '16px' }}>Grund: {selectedEvent.grund}</Typography>
          ) : (
            terminDetails && (
              <>
                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Kunde:</Typography>
                <Typography>Einrichtung: {terminDetails.kunde.einrichtung}</Typography>
                <Typography>Ansprechpartner:in: {terminDetails.kunde.ansprechpartner}</Typography>
                <Typography>Email: {terminDetails.kunde.email}</Typography>
                <Typography>Telefon: {terminDetails.kunde.telefon}</Typography>
                
                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Weitere Details:</Typography>
                <Typography>Anzahl Teilnehmende: {terminDetails.anzahltn}</Typography>
                <Typography>Sonderwünsche: {terminDetails.sonderwuensche || 'Keine'}</Typography>

                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Team:</Typography>
                <List>
                  {coWorkers.map((worker, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={worker} />
                    </ListItem>
                  ))}
                </List>
              </>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schliessen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmployeeTerminKalender;