// Dashboard.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions, useAuthState } from 'react-admin';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { permissions, isLoading: permissionsLoading } = usePermissions();
  const { authenticated, isLoading: authLoading } = useAuthState();

  useEffect(() => {
    if (authLoading || permissionsLoading) return;

    if (!authenticated) return;

    if (permissions === 'admin') {
      navigate('/termin-kalender');
    } else if (permissions) {
      navigate('/mitarbeiter-kalender');
    }
  }, [permissions, permissionsLoading, authenticated, authLoading, navigate]);

  return null;
};

export default Dashboard;