import React from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField } from 'react-admin';

export const TerminvorschlagList = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="bestellung_id" reference="bestellung">
                <TextField source="id" />
            </ReferenceField>
            <DateField source="vorschlagdatetime" showTime />
            <TextField source="status" />
        </Datagrid>
    </List>
);