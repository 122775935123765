import React from 'react';
import { Create, SimpleForm, TextInput, regex } from 'react-admin';

const validateColor = regex(
    /^#[0-9A-Fa-f]{6}$/,
    'Must be a valid hex color code (e.g., #FFFFFF)'
);

export const MuseumCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="adresse" multiline />
            <TextInput source="plz" />
            <TextInput source="ort" />
            <TextInput
                source="color"
                type="color"
                validate={validateColor}
            />
        </SimpleForm>
    </Create>
);
