import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    NumberField,
    SearchInput,
    FunctionField,
    useRecordContext
} from 'react-admin';
import moment from 'moment-timezone';

interface Terminvorschlag {
    id: number;
    bestellung_id: number;
    vorschlagdatetime: string;
    status: string;
}

interface ArchivedBestellung {
    id: number;
    terminvorschlaege?: Terminvorschlag[];
}

const archivedBestellungFilters = [
    <SearchInput source="q" alwaysOn placeholder="Suche nach Id" />,
];

const ArchivedTerminvorschlagField = () => {
    const record = useRecordContext<ArchivedBestellung>();
    if (!record || !record.terminvorschlaege) {
        return <>-</>;
    }
    
    const matchingTerminvorschlag = record.terminvorschlaege.find((tv: Terminvorschlag) => tv.status === "akzeptiert");
    
    if (matchingTerminvorschlag) {
        const localTime = moment.utc(matchingTerminvorschlag.vorschlagdatetime)
            .local()
            .format('DD.MM.YYYY HH:mm:ss');
        return <span>{localTime}</span>;
    }
    return <>-</>;
};

export const ArchivedBestellungList: React.FC = (props) => (
    <List 
        {...props} 
        filters={archivedBestellungFilters}
        resource="bestellung"
        filter={{ archived: true }}
    >
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="kunde_id" reference="kunde">
                <TextField source="einrichtung" />
            </ReferenceField>
            <ReferenceField source="kunde_id" reference="kunde" label="Ansprechpartner">
                <TextField source="ansprechpartner" />
            </ReferenceField>
            <ReferenceField source="produkt_id" reference="produkt">
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="anzahltn" />
            <TextField source="sonderwuensche" />
            <TextField source="status" />
            <FunctionField label="Terminvorschlag" render={ArchivedTerminvorschlagField} />
        </Datagrid>
    </List>
);

export default ArchivedBestellungList;