import React, { useEffect, useState, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { Autocomplete, TextField } from '@mui/material';
import { BestellungData } from './NeueBestellungWizard';

interface Produkt {
    id: number;
    name: string;
}

interface ProduktAuswahlProps {
    bestellungData: BestellungData;
    setBestellungData: React.Dispatch<React.SetStateAction<BestellungData>>;
}

const ProduktAuswahl: React.FC<ProduktAuswahlProps> = ({ bestellungData, setBestellungData }) => {
    const [produkte, setProdukte] = useState<Produkt[]>([]);
    const [inputValue, setInputValue] = useState('');
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchProdukte = async () => {
            const { data } = await dataProvider.getList('produkt', {
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'name', order: 'ASC' },
                filter: {}
            });
            setProdukte(data);
        };
        fetchProdukte();
    }, [dataProvider]);

    const filteredProdukte = useMemo(() => {
        const lowercasedInput = inputValue.toLowerCase();
        return produkte.filter(produkt => 
            produkt.name.toLowerCase().includes(lowercasedInput)
        );
    }, [produkte, inputValue]);

    const handleProduktChange = (event: React.SyntheticEvent, value: Produkt | null) => {
        setBestellungData(prev => ({ ...prev, produkt_id: value ? value.id : null }));
    };

    return (
        <Autocomplete
            options={filteredProdukte}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Produkt auswählen" />}
            value={produkte.find(produkt => produkt.id === bestellungData.produkt_id) || null}
            onChange={handleProduktChange}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={(x) => x} // Use our custom filtering
        />
    );
};

export default ProduktAuswahl;