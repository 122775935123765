import React, { useState, useEffect } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';

moment.locale('de', {
  week: {
    dow: 1,
    doy: 4
  }
});

const localizer = momentLocalizer(moment);

interface CalendarEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  status: string;
  color: string;
  bestellungId?: number;
  isBlockierung: boolean;
  grund?: string;
  museumId: number;
  museumName: string;
}

interface PopupProps {
  event: CalendarEvent;
  onClose: () => void;
  onEdit: () => void;
}

const BlockierungPopup: React.FC<PopupProps> = ({ event, onClose, onEdit }) => {
  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      zIndex: 1000
    }}>
      <h3>{event.title}</h3>
      <p>Museum: {event.museumName}</p>
      <p>Start: {moment(event.start).format('DD.MM.YYYY HH:mm')}</p>
      <p>Ende: {moment(event.end).format('DD.MM.YYYY HH:mm')}</p>
      <p>Grund: {event.grund}</p>
      <button onClick={onEdit}>Bearbeiten</button>
      <button onClick={onClose}>Schließen</button>
    </div>
  );
};

const TerminKalender = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  useEffect(() => {
    const fetchEvents = async () => {
      // Fetch termine
      const { data: termine } = await dataProvider.getList('terminvorschlag', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'vorschlagdatetime', order: 'ASC' },
        filter: { status: 'akzeptiert' },
      });

      // Fetch blockierungen
      const { data: blockierungen } = await dataProvider.getList('blockierung', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'start', order: 'ASC' },
      });

      const terminePromises = termine.map(async (termin: any) => {
        const { data: bestellung } = await dataProvider.getOne('bestellung', { id: termin.bestellung_id });
        
        // Skip if bestellung is canceled
        if (bestellung.status === 'storniert') {
          return null;
        }
        
        const { data: produkt } = await dataProvider.getOne('produkt', { id: bestellung.produkt_id });
        const { data: museum } = await dataProvider.getOne('museum', { id: produkt.museum_id });

        const startTime = moment.utc(termin.vorschlagdatetime).local().toDate();
        const endTime = moment.utc(termin.vorschlagdatetime).local().add(produkt.dauer, 'hours').toDate();
         
        return {
          id: termin.id,
          title: `${produkt.name} - ${museum.name}`,
          start: startTime,
          end: endTime,
          status: termin.status,
          color: museum.color || '#3174ad',
          bestellungId: bestellung.id,
          isBlockierung: false,
          museumId: museum.id,
          museumName: museum.name,
        };
      });

      const blockierungenPromises = blockierungen.map(async (blockierung: any) => {
        const { data: museum } = await dataProvider.getOne('museum', { id: blockierung.museum_id });
        return {
          id: blockierung.id,
          title: `${blockierung.name}`,
          start: moment.utc(blockierung.start).local().toDate(),
          end: moment.utc(blockierung.ende).local().toDate(),
          status: 'blockierung',
          color: 'red',
          isBlockierung: true,
          grund: blockierung.grund,
          museumId: museum.id,
          museumName: museum.name,
        };
      });

      const termineEvents = (await Promise.all(terminePromises))
        .filter((event): event is NonNullable<typeof event> => event !== null);
      const blockierungenEvents = await Promise.all(blockierungenPromises);
      setEvents([...termineEvents, ...blockierungenEvents]);
    };

    fetchEvents();
  }, [dataProvider]);

  const eventStyleGetter = (event: CalendarEvent) => {
    if (event.isBlockierung) {
      return {
        style: {
          backgroundColor: 'red',
          backgroundImage: 'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(255,255,255,.5) 10px, rgba(255,255,255,.5) 20px)',
          color: 'white',
          fontWeight: 'bold',
        },
      };
    }
    return {
      style: {
        backgroundColor: event.color,
      },
    };
  };

  const handleEventClick = (event: CalendarEvent) => {
    if (event.isBlockierung) {
      setSelectedEvent(event);
    } else if (event.bestellungId) {
      redirect('edit', 'bestellung', event.bestellungId);
    }
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
  };

  const handleEditBlockierung = () => {
    if (selectedEvent) {
      // Redirect directly to the blockierung edit page
      redirect('edit', 'blockierung', selectedEvent.id);
      setSelectedEvent(null); // Close the popup after redirecting
    }
  };

  return (
    <div style={{ 
      height: 'calc(100vh - 100px)',
      maxWidth: '100%',
      overflow: 'hidden'
    }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter}
        onSelectEvent={handleEventClick}
        views={['month', 'week', 'day']}
        formats={{
          timeGutterFormat: 'HH:mm',
          eventTimeRangeFormat: ({ start, end }: { start: Date; end: Date }) => (
            `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
          ),
        }}
      />
      {selectedEvent && (
        <BlockierungPopup 
          event={selectedEvent} 
          onClose={handleClosePopup} 
          onEdit={handleEditBlockierung} 
        />
      )}
    </div>
  );
};

export default TerminKalender;