import React from 'react';
import { Create, SimpleForm, ReferenceInput, SelectInput, NumberInput, TextInput } from 'react-admin';

export const BestellungCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="kunde_id" reference="kunde">
                <SelectInput optionText="schule" />
            </ReferenceInput>
            <ReferenceInput source="produkt_id" reference="produkt">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="anzahltn" />
            <TextInput multiline source="sonderwuensche" />
            <SelectInput source="status" choices={[
                { id: 'neu', name: 'Neu' },
                { id: 'in_bearbeitung', name: 'In Bearbeitung' },
                { id: 'abgeschlossen', name: 'Abgeschlossen' },
                { id: 'storniert', name: 'Storniert' },
            ]} />
        </SimpleForm>
    </Create>
);