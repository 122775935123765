import { AuthProvider } from 'react-admin';
import { supabase } from './supabaseClient';

const authProvider: AuthProvider = {
    login: async () => {
        // The user is already logged in via Supabase Auth UI
        return Promise.resolve();
    },
    logout: async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            return Promise.reject(error.message);
        }
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkError: ({ status }: { status: number }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('user');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
    getPermissions: () => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        return Promise.resolve(user.role);
    },
    getIdentity: () => {
        const user = localStorage.getItem('user');
        return user ? Promise.resolve(JSON.parse(user)) : Promise.reject();
    },
};

export default authProvider;