// CustomMenu.tsx
import React from 'react';
import {
  Menu,
  MenuItemLink,
  useTranslate,
  MenuProps,
  usePermissions,
} from 'react-admin';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import EmployeeIcon from '@mui/icons-material/People';
import MuseumIcon from '@mui/icons-material/AccountBalance';
import ProductIcon from '@mui/icons-material/Category';
import CustomerIcon from '@mui/icons-material/Person';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import WorkIcon from '@mui/icons-material/Work';
import BlockIcon from '@mui/icons-material/Block';
import ArchiveIcon from '@mui/icons-material/Archive';

const CustomMenu: React.FC<MenuProps> = (props: MenuProps) => {
  const translate = useTranslate();
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <Menu {...props}>
      {permissions === 'admin' && (
        <>
          <MenuItemLink
            to="/mitarbeiter"
            primaryText={translate('Mitarbeiter:innen')}
            leftIcon={<EmployeeIcon />}
          />
          <MenuItemLink
            to="/museum"
            primaryText={translate('Museen')}
            leftIcon={<MuseumIcon />}
          />
          <MenuItemLink
            to="/produkt"
            primaryText={translate('Produkt')}
            leftIcon={<ProductIcon />}
          />
          <MenuItemLink
            to="/kunde"
            primaryText={translate('Kund:innen')}
            leftIcon={<CustomerIcon />}
          />
          <MenuItemLink
            to="/bestellung"
            primaryText={translate('Bestellungen')}
            leftIcon={<OrderIcon />}
          />
          <MenuItemLink
            to="/archived-bestellungen"
            primaryText={translate('Archiv')}
            leftIcon={<ArchiveIcon />}
          />
          <MenuItemLink
            to="/blockierung"
            primaryText={translate('Blockierungen')}
            leftIcon={<BlockIcon />}
          />
          <MenuItemLink
            to="/termin-kalender"
            primaryText={translate('Termin Kalender')}
            leftIcon={<CalendarIcon />}
          />
        </>
      )}
      {permissions !== 'admin' && permissions && (
        <MenuItemLink
          to="/mitarbeiter-kalender"
          primaryText={translate('Mein Kalender')}
          leftIcon={<WorkIcon />}
        />
      )}
    </Menu>
  );
};

export default CustomMenu;