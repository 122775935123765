// AngenommenCheckbox.tsx
import React, { useState } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Checkbox, FormControlLabel } from '@mui/material';
import { sendConfirmationEmail, sendRejectionEmail, sendCustomerConfirmationEmail } from './sendEmail';
import { sendEinzelauftrag } from './sendEinzelauftrag';

export const CHECKBOX_CLICKED_EVENT = 'ANGENOMMEN_CHECKBOX_CLICKED';

interface RecordType {
    id: number;
    angenommen: boolean;
    mitarbeiter_id: number;
    terminvorschlag_id: number;
}

const AngenommenCheckbox: React.FC<{ record: RecordType }> = ({ record }) => {
    const [selected, setSelected] = useState<boolean>(record.angenommen);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        e.stopPropagation();
        window.dispatchEvent(new CustomEvent(CHECKBOX_CLICKED_EVENT));

        try {
            // Update the 'angenommen' status
            const { data: previousData } = await dataProvider.getOne('relmitarbeiterstatus', {
                id: record.id,
            });

            await dataProvider.update('relmitarbeiterstatus', {
                id: record.id,
                data: { angenommen: checked },
                previousData,
            });

            setSelected(checked);
            notify('Angenommen status updated', { type: 'success' });
            refresh();

            if (checked) {
                try {
                    // Send confirmation email
                    await sendConfirmationEmail(
                        dataProvider,
                        notify,
                        record.mitarbeiter_id,
                        record.terminvorschlag_id
                    );
                    notify('Bestätigung versendet', { type: 'success' });

                    // Prepare data for sendEinzelauftrag
                    const einzelauftragData = {
                        mitarbeiterId: record.mitarbeiter_id,
                        terminvorschlagId: record.terminvorschlag_id,
                        produktName: '', // Will be fetched in sendEinzelauftrag
                        termin: '', // Will be fetched in sendEinzelauftrag
                        museum: '', // Will be fetched in sendEinzelauftrag
                        mitarbeiterEmail: '', // Will be fetched in sendEinzelauftrag
                        mitarbeiterVorname: '', // Will be fetched in sendEinzelauftrag
                        mitarbeiterNachname: '', // Will be fetched in sendEinzelauftrag
                    };

                    // Send Einzelauftrag
                    const slug = await sendEinzelauftrag(dataProvider, einzelauftragData);
                    notify(`Einzelauftrag versendet.`, { type: 'success' });

                    // Re-fetch latest terminvorschlag data
                    const { data: currentTerminvorschlag } = await dataProvider.getOne('terminvorschlag', { id: record.terminvorschlag_id });
                    const { data: bestellung } = await dataProvider.getOne('bestellung', { id: currentTerminvorschlag.bestellung_id });
                    const { data: produkt } = await dataProvider.getOne('produkt', { id: bestellung.produkt_id });

                    // Re-fetch accepted employees
                    const { data: acceptedEmployees } = await dataProvider.getList('relmitarbeiterstatus', {
                        filter: { terminvorschlag_id: record.terminvorschlag_id, angenommen: true },
                        pagination: { page: 1, perPage: 1000 },
                        sort: { field: 'id', order: 'ASC' },
                    });

                    if (acceptedEmployees.length === produkt.anzahlmitarbeiter) {
                        // Update terminvorschlag status to "akzeptiert"
                        await dataProvider.update('terminvorschlag', {
                            id: record.terminvorschlag_id,
                            data: { status: 'akzeptiert' },
                            previousData: currentTerminvorschlag,
                        });

                        // Optional: Add a one-second delay
                        await new Promise(resolve => setTimeout(resolve, 1000)); // 1-second delay

                        // Re-fetch the updated terminvorschlag
                        const { data: updatedTerminvorschlag } = await dataProvider.getOne('terminvorschlag', { id: record.terminvorschlag_id });

                        // Check if the status is now 'akzeptiert'
                        if (updatedTerminvorschlag.status === 'akzeptiert') {
                            // Get customer details
                            const { data: kunde } = await dataProvider.getOne('kunde', { id: bestellung.kunde_id });

                            // Send confirmation email to the customer
                            await sendCustomerConfirmationEmail(
                                dataProvider,
                                notify,
                                kunde,
                                currentTerminvorschlag,
                                produkt,
                                acceptedEmployees
                            );

                            // Get all terminvorschläge for this bestellung
                            const { data: allTerminvorschlaege } = await dataProvider.getList('terminvorschlag', {
                                filter: { bestellung_id: bestellung.id },
                                pagination: { page: 1, perPage: 1000 },
                                sort: { field: 'id', order: 'ASC' },
                            });

                            // Get all accepted employees for this bestellung
                            const acceptedEmployeesSet = new Set<number>();
                            for (const termin of allTerminvorschlaege) {
                                const { data: acceptedForTermin } = await dataProvider.getList('relmitarbeiterstatus', {
                                    filter: {
                                        terminvorschlag_id: termin.id,
                                        bestaetigt: true,
                                        angenommen: true,
                                    },
                                    pagination: { page: 1, perPage: 1000 },
                                });
                                acceptedForTermin.forEach(emp => acceptedEmployeesSet.add(emp.mitarbeiter_id));
                            }

                            // Process each Terminvorschlag
                            for (const termin of allTerminvorschlaege) {
                                // Get all employees for this Terminvorschlag
                                const { data: employees } = await dataProvider.getList('relmitarbeiterstatus', {
                                    filter: {
                                        terminvorschlag_id: termin.id,
                                        bestaetigt: true,
                                    },
                                    pagination: { page: 1, perPage: 1000 },
                                    sort: { field: 'id', order: 'ASC' },
                                });

                                // Send rejections
                                for (const employee of employees) {
                                    if (!acceptedEmployeesSet.has(employee.mitarbeiter_id) && !employee.angenommen) {
                                        // Send rejection email only if the employee wasn't accepted for any Terminvorschlag
                                        await sendRejectionEmail(
                                            dataProvider,
                                            notify,
                                            employee.mitarbeiter_id,
                                            termin.id
                                        );
                                    }
                                }
                            }

                            notify('Alle Beteiligten wurden benachrichtigt', { type: 'success' });
                        } else {
                            // Status is not 'akzeptiert'; do not send rejection emails
                            notify('Terminvorschlag wurde bereits verarbeitet.', { type: 'info' });
                        }
                    } else {
                        // Not enough employees yet; do nothing or provide feedback
                        notify('Noch nicht genügend Mitarbeiter angenommen.', { type: 'info' });
                    }
                } catch (error) {
                    console.error('Error sending confirmation email or Einzelauftrag:', error);
                    notify('Fehler beim Senden des Einzelauftrags', { type: 'error' });
                }
            }
        } catch (error) {
            console.error('Error updating angenommen status:', error);
            notify('Fehler beim Aktualisieren des Angenommen-Status', { type: 'error' });
        }
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={selected}
                    onChange={handleChange}
                    onClick={handleClick}
                />
            }
            label="Angenommen"
            onClick={handleClick}
        />
    );
};

export default AngenommenCheckbox;