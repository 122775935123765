import React from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField } from 'react-admin';

export const BlockierungList = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="museum_id" reference="museum">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="grund" />
            <DateField source="start" showTime />
            <DateField source="ende" showTime />
        </Datagrid>
    </List>
);