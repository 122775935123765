import React from 'react';
import { TextField } from '@mui/material';
import { BestellungData } from './NeueBestellungWizard';

interface BestellungDetailsProps {
    bestellungData: BestellungData;
    setBestellungData: React.Dispatch<React.SetStateAction<BestellungData>>;
}

const BestellungDetails: React.FC<BestellungDetailsProps> = ({ bestellungData, setBestellungData }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setBestellungData(prev => ({ ...prev, [name]: name === 'anzahltn' ? parseInt(value) : value }));
    };

    return (
        <>
            <TextField
                fullWidth
                label="Anzahl Teilnehmer"
                type="number"
                name="anzahltn"
                value={bestellungData.anzahltn}
                onChange={handleChange}
                margin="normal"
            />
            <TextField
                fullWidth
                label="Sonderwünsche"
                multiline
                rows={4}
                name="sonderwuensche"
                value={bestellungData.sonderwuensche}
                onChange={handleChange}
                margin="normal"
            />
        </>
    );
};

export default BestellungDetails;