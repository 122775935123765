import React from 'react';
import { Edit, SimpleForm, TextInput, DateTimeInput, ReferenceInput, SelectInput } from 'react-admin';

export const BlockierungEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="museum_id" reference="museum">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" />
            <TextInput source="grund" multiline />
            <DateTimeInput source="start" />
            <DateTimeInput source="ende" />
        </SimpleForm>
    </Edit>
);