import React, { useState } from 'react';
import { useNotify, Notification } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { supabase } from '../supabaseClient';
import { useTheme, alpha } from '@mui/material/styles';


const LoginPage: React.FC = () => {
    const theme = useTheme();
    const notify = useNotify();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({ email: '', password: '' });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const { data, error } = await supabase.auth.signInWithPassword({
            email: credentials.email,
            password: credentials.password,
        });

        if (error) {
            setLoading(false);
            notify(error.message, { type: 'warning' });
            return;
        }

        if (data.session) {
            // Fetch mitarbeiter details
            const { data: mitarbeiterData, error: mitarbeiterError } = await supabase
                .from('mitarbeiter')
                .select('*')
                .eq('auth_user_id', data.user.id)
                .single();

            if (mitarbeiterError) {
                setLoading(false);
                notify('Error fetching user details', { type: 'warning' });
                return;
            }

            // Store user data including mitarbeiter details and role
            const userData = {
                ...data.user,
                mitarbeiter: mitarbeiterData,
                role: mitarbeiterData.is_admin ? 'admin' : 'mitarbeiter',
            };
            localStorage.setItem('user', JSON.stringify(userData));
            navigate('/');
        } else {
            setLoading(false);
            notify('Login failed', { type: 'warning' });
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.background.default,
                backgroundImage: 'url(https://unsplash.it/1920/1080?random)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <Card
                sx={{
                    minWidth: 300,
                    padding: '2em',
                    backgroundColor: alpha(theme.palette.background.paper, 0.85),
                }}
            >
                {/* Add a logo if you have one */}
                {/* <img src="your-logo-url" alt="Logo" style={{ display: 'block', margin: '0 auto', marginBottom: '1em', width: 100 }} /> */}
                <Avatar
                    sx={{
                        margin: '0 auto',
                        marginBottom: '1em',
                        backgroundColor: theme.palette.secondary.main,
                    }}
                >
                    <LockIcon />
                </Avatar>
                <Typography variant="h4" align="center">
                    KuBi PF Copilot
                </Typography>
                <form onSubmit={handleSubmit}>
                    <div
                        style={{
                            padding: '0 1em 1em 1em',
                        }}
                    >
                        <TextField
                            name="email"
                            label="E-Mail"
                            fullWidth
                            required
                            onChange={handleChange}
                            disabled={loading}
                            sx={{ marginTop: '1em' }}
                        />
                        <TextField
                            name="password"
                            label="Passwort"
                            type="password"
                            fullWidth
                            required
                            onChange={handleChange}
                            disabled={loading}
                            sx={{ marginTop: '1em' }}
                        />
                    </div>
                    <CardActions
                        sx={{
                            padding: '0 1em 1em 1em',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20} thickness={2} /> : null}
                        >
                            Anmelden
                        </Button>
                    </CardActions>
                </form>
            </Card>
            <Notification />
        </div>
    );
};

export default LoginPage;