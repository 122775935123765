import React from 'react';
import { useRecordContext, useUpdate, Button, useRefresh, useNotify } from 'react-admin';
import ArchiveIcon from '@mui/icons-material/Archive';

const ArchiveButton = () => {
    const record = useRecordContext();
    const [update] = useUpdate();
    const refresh = useRefresh();
    const notify = useNotify();

    if (!record) return null;

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        update(
            'bestellung',
            { 
                id: record.id,
                data: { 
                    archived: true,
                    archived_at: new Date().toISOString()
                }
            },
            {
                mutationMode: 'pessimistic',
                onSuccess: () => {
                    notify('Bestellung wurde archiviert', { type: 'success' });
                    refresh();
                },
                onError: (error) => {
                    notify('Fehler beim Archivieren: ' + error.message, { type: 'error' });
                }
            }
        );
    };

    return (
        <Button
            label="Archivieren"
            onClick={handleClick}
            color="warning"
        >
            <ArchiveIcon />
        </Button>
    );
};

export default ArchiveButton;