import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Autocomplete, TextField } from '@mui/material';
import { BestellungData } from './NeueBestellungWizard';

interface Kunde {
    id: number;
    einrichtung: string;
    ansprechpartner: string;
}

interface KundeAuswahlProps {
    bestellungData: BestellungData;
    setBestellungData: React.Dispatch<React.SetStateAction<BestellungData>>;
}

const KundeAuswahl: React.FC<KundeAuswahlProps> = ({ bestellungData, setBestellungData }) => {
    const [kunden, setKunden] = useState<Kunde[]>([]);
    const [inputValue, setInputValue] = useState('');
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchKunden = async () => {
            const { data } = await dataProvider.getList('kunde', {
                pagination: { page: 1, perPage: 400 },
                sort: { field: 'ansprechpartner', order: 'ASC' },
                filter: {}
            });
            setKunden(data);
        };
        fetchKunden();
    }, [dataProvider]);

    const handleKundeChange = (event: React.SyntheticEvent, value: Kunde | null) => {
        setBestellungData({ ...bestellungData, kunde_id: value ? value.id : null });
    };

    const filterOptions = (options: Kunde[], { inputValue }: { inputValue: string }) => {
        const filterValue = inputValue.toLowerCase();
        return options.filter(
            (option) =>
                option.ansprechpartner.toLowerCase().includes(filterValue) ||
                option.einrichtung.toLowerCase().includes(filterValue)
        );
    };

    return (
        <Autocomplete
            options={kunden}
            getOptionLabel={(option) => `${option.ansprechpartner} | ${option.einrichtung}`}
            renderInput={(params) => <TextField {...params} label="Ansprechpartner auswählen" />}
            value={kunden.find(kunde => kunde.id === bestellungData.kunde_id) || null}
            onChange={handleKundeChange}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};

export default KundeAuswahl;