import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useDataProvider, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { sendEmailToMitarbeiter } from './sendEmail';

const AddMitarbeiterButton: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [selectedMitarbeiter, setSelectedMitarbeiter] = useState<number | ''>('');
    const [mitarbeiterList, setMitarbeiterList] = useState<Array<{ id: number; name: string }>>([]);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();

    const fetchMitarbeiter = useCallback(async () => {
        try {
            const { data } = await dataProvider.getList('mitarbeiter', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'nachname', order: 'ASC' },
                filter: {},
            });
            setMitarbeiterList(data.map(m => ({ id: m.id, name: `${m.vorname} ${m.nachname}` })));
        } catch (error) {
            console.error('Error fetching mitarbeiter:', error);
            notify('Error fetching mitarbeiter list', { type: 'error' });
        }
    }, [dataProvider, notify]);

    const handleOpen = () => {
        setOpen(true);
        fetchMitarbeiter();
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMitarbeiter('');
    };

    const handleAdd = async () => {
        if (selectedMitarbeiter && record && record.id) {
            try {
                // Fetch all Terminvorschläge for this Bestellung
                const { data: terminvorschlaege } = await dataProvider.getList('terminvorschlag', {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'id', order: 'ASC' },
                    filter: { bestellung_id: record.id },
                });

                // Create relmitarbeiterstatus entries for each Terminvorschlag
                for (const terminvorschlag of terminvorschlaege) {
                    try {
                        await dataProvider.create('relmitarbeiterstatus', {
                            data: {
                                terminvorschlag_id: terminvorschlag.id,
                                mitarbeiter_id: selectedMitarbeiter,
                                bestaetigt: false,
                                angenommen: false,
                            },
                        });
                    } catch (error) {
                        if (error instanceof Error && error.message.includes('duplicate key value violates unique constraint')) {
                            console.log(`Mitarbeiter already assigned to Terminvorschlag ${terminvorschlag.id}`);
                        } else {
                            throw error;
                        }
                    }
                }

                // Fetch bestellung details
                const { data: bestellung } = await dataProvider.getOne('bestellung', { id: record.id });

                // Prepare bestellung details for email
                const bestellungDetails = `
                    <ul>
                        <li>Bestellungs-ID: ${bestellung.id}</li>
                        <li>Kunde ID: ${bestellung.kunde_id}</li>
                        <li>Produkt ID: ${bestellung.produkt_id}</li>
                        <li>Anzahl Teilnehmer: ${bestellung.anzahltn}</li>
                        <li>Sonderwünsche: ${bestellung.sonderwuensche || 'Keine'}</li>
                        <li>Status: ${bestellung.status}</li>
                    </ul>
                `;

                // Send email to the newly added mitarbeiter
                await sendEmailToMitarbeiter(dataProvider, notify, selectedMitarbeiter, bestellungDetails, terminvorschlaege);

                notify('Mitarbeiter successfully added to all Terminvorschläge', { type: 'success' });
                refresh();
                handleClose();
            } catch (error) {
                console.error('Error adding mitarbeiter:', error);
                notify('Error adding mitarbeiter', { type: 'error' });
            }
        }
    };

    return (
        <>
            <Button onClick={handleOpen} variant="outlined">
                Mitarbeiter:in hinzufügen
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Mitarbeiter:in hinzufügen</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Mitarbeiter:in auswählen</InputLabel>
                        <Select
                            value={selectedMitarbeiter}
                            onChange={(e) => setSelectedMitarbeiter(e.target.value as number)}
                        >
                            {mitarbeiterList.map((mitarbeiter) => (
                                <MenuItem key={mitarbeiter.id} value={mitarbeiter.id}>
                                    {mitarbeiter.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={handleAdd} disabled={!selectedMitarbeiter}>
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddMitarbeiterButton;