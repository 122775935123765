import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput } from 'react-admin';

export const MitarbeiterEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="vorname" />
            <TextInput source="nachname" />
            <TextInput source="adresse" multiline />
            <TextInput source="plz" />
            <TextInput source="ort" />
            <TextInput source="email" type="email" />
            <TextInput source="festnetz" />
            <TextInput source="mobil" />
            <NumberInput source="fahrtkosten" />
            <BooleanInput source="umsatzsteuer" />
        </SimpleForm>
    </Edit>
);